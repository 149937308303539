import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from "gatsby";

export const data = graphql`query BudgetPlannerQuery {
  file(relativePath: {eq: "NemoBudgetPlanner.pdf"}) {
    publicURL
  }
}
`

const BudgetPlannerPage = ({data}) => (

    <Layout>
        <Seo title={'Try our budget planner for existing Nemo customers'} description={'Want help pinpointing between your income and expenditure? Fill in our budget planner for existing customers today and get a clear picture of your monthly outgoings.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/help-with-repayments/dealing-with-difficult-times">Dealing
                            with difficult times</a></li>
                        <li><a href="/help-with-repayments/useful-information">Useful
                            information</a></li>
                        <li><a href="/help-with-repayments/budget-planner-intro" className={'selected'}>Budget
                            Planner</a></li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Budget Planner</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading"></p>
                        <p>When you owe money it can be a daunting prospect, but the first step is to understand which
                            debts are the most important to repay. These aren’t always the largest debts, the debts with
                            the highest interest rate or from the lenders who shout the loudest. They are the debts that
                            if you don’t pay them, you could end up in Court, losing your home, or being declared
                            bankrupt. These are sometimes referred to as priority debts. </p>
                        <p>
                            <strong>Is my Nemo mortgage a priority debt?</strong></p>
                        <p>Your Nemo mortgage is secured against your property and is therefore classed as a priority
                            debt. It is important to pay both your first charge mortgage and Nemo second charge mortgage
                            to ensure you do not lose your home.</p>
                        <p>
                            <strong>Work out your budget </strong>
                        </p>
                        <p>You can use our free Budget Planner to help you work out your budget. The benefits of
                            completing a Budget Planner are:</p>
                        <ul>
                            <li>It’s the first step in managing your finances</li>
                            <li>It allows you to stay in control of your finances</li>
                            <li>It helps you prioritise the most important debts and bills</li>
                            <li>You can see where your money goes and where you can cut back</li>
                            <li>It helps you budget for unexpected bills</li>
                            <li>You can ensure that any offer you make is realistic, affordable and sustainable</li>
                        </ul>
                        <p>
                            <strong>Guide to completing the Budget Planner</strong></p>
                            <ul>
                                <li>To convert weekly amounts into monthly, multiply the weekly figure by 52 and divide
                                    by 12
                                </li>
                                <li>To convert four weekly amounts into monthly (for example, benefits payments)
                                    multiply four weekly figure by 13 and divide by 12
                                </li>
                                <li>To convert fortnightly payments into monthly, multiply fortnightly figure by 26 and
                                    divide by 12
                                </li>
                                <li>The Budget Planner is based on MONTHLY figures. All quarterly or annual items (for
                                    example car tax, TV License fee) should be converted to monthly
                                </li>
                                <li>If you're not sure of an amount for something which might vary (like utility bills)
                                    try and take a monthly average based on the whole year
                                </li>
                                <li>If you are in an arrangement to pay arrears on priority debts, for example, your
                                    first mortgage, please include this increased amount
                                </li>
                                <li>Your Disposable Income sum (in the ‘MONTHLY SUMMARY' section) should first be used
                                    towards the clearance of arrears outstanding for Priority Expenditure items. Any
                                    remaining sums should be used to make payments to your unsecured creditors and for
                                    your Non-Priority outgoings
                                </li>
                            </ul>
                        <p>
                            <a href={data.file.publicURL} className="btn round purple">
                                Budget Planner
                                <span className="tl"></span>
                                <span className="tr"></span>
                                <span className="bl"></span>
                                <span className="br"></span>
                            </a>
                        </p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </div>
                </div>
                <div className="fbCol">
                    {/*<div className="fbList">*/}
                    {/*    <ul>*/}
                    {/*        <li className="phone">*/}
                    {/*            <div className="top"></div>*/}
                    {/*            <div className="txtWrap">*/}
                    {/*                <h3>Want us to call you?</h3>*/}
                    {/*                <p>*/}
                    {/*                    <a href="/customer-service/callback-request">Request*/}
                    {/*                        a call back </a>from Nemo</p>*/}
                    {/*            </div>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </section>
        </div>
    </Layout>
)

export default BudgetPlannerPage;